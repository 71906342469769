import React from "react";
import styles from "./UtableRow.module.css";
import { Delete } from "@mui/icons-material";
import { deleteNotification } from "context/apis/api";

const JTableRow = ({ id, index, name, email, phone, position, resume }) => {
//   const deleteHandler = async () => {
//     const yes = window.confirm(
//       "Are you sure you want to delete this notification?"
//     );
//     if (yes) {
//       await deleteNotification({
//         id: id,
//       });
//       window.location.reload();
//       // Delete the notification
//     }
//   };
  return (
    <>
      <tr className={styles.tableRow}>
        <td>{index + 1}</td>
        <td>{name}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <td>{position}</td>
        <td>{resume.length > 0 ? <a href={resume} target="_blank" rel="noreferrer">View</a> : "-"}</td>
        {/* <td onClick={deleteHandler}>
          <Delete color="error" />
        </td> */}
      </tr>
    </>
  );
};

export default JTableRow;
