import React, { Suspense, useContext, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "./context/actions/AuthContext";
import PageLoader from "./ui/PageLoader/PageLoader";
import Cookies from "js-cookie";
import ApprovedListingDetails from "screens/ApprovedListingDetails/ApprovedListingDetails";
import UnApprovedListing from "screens/UnApprovedListing/UnApprovedListing";
import UnApprovedListingDetails from "screens/UnApprovedListingDetails/UnApprovedListingDetails";
import TransactionDetail from "screens/TransactionDetails/TransactionDetail";
import ListingOrders from "screens/ListingOrders/ListingOrders";
import NTable from "components/UTable/NotificationTable";
import JobApplication from "screens/JobApplication/JobApplication";

const Login = lazy(() => import("./screens/Login/Login"));
const User = lazy(() => import("./screens/User/User"));
const Notification = lazy(() => import("./screens/Notification/Notification"));
const UserDetails = lazy(() => import("./screens/UserDetails/UserDetails"));
const Raiser = lazy(() => import("./screens/Raiser/Raiser"));
const RaisersDetails = lazy(() =>
  import("./screens/RaiserDetails/RaisersDetails")
);
const ApprovedListing = lazy(() =>
  import("./screens/ApprovedListing/ApprovedListing")
);
const Transactions = lazy(() => import("./screens/Transactions/Transactions"));
const Subadmin = lazy(() => import("./screens/Subadmin/Subadmin"));

const LogoutHandler = () => {
  const { setCurrentUser } = useContext(AuthContext);
  const token = Cookies.get("unicapAdminToken")
    ?.replace(`"`, "")
    ?.replace(`"`, "");
  const location = useLocation();
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        Cookies.remove("unicapAdminToken");
        setCurrentUser({
          id: null,
        });
      }
    }
  }, [location.pathname]);

  return null;
};

const App = () => {
  const { currentUser } = useContext(AuthContext);
  const token =
    currentUser?.id ||
    Cookies.get("unicapAdminToken")?.replace(`"`, "")?.replace(`"`, "");

  return (
    <Router>
      <LogoutHandler />
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route exact path="/">
            {token ? <Redirect to="/users" /> : <Login />}
          </Route>
          <Route exact path="/users">
            {token ? <User /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/notification">
            {token ? <Notification /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/raisers">
            {token ? <Raiser /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/users/:id">
            {token ? <UserDetails /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/raisers/:id">
            {token ? <RaisersDetails /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/approved-listing">
            {token ? <ApprovedListing /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/approved-listing/:listingId">
            {token ? <ApprovedListingDetails /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/approved-listing/investment/:id">
            {token ? <ListingOrders /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/unapproved-listing">
            {token ? <UnApprovedListing /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/unapproved-listing/:listingId">
            {token ? <UnApprovedListingDetails /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/investments">
            {token ? <Transactions /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/investments/:listingId">
            {token ? <TransactionDetail /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/sub-admins">
            {token ? <Subadmin /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/applied-position">
            {token ? <JobApplication /> : <Redirect to="/" />}
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
