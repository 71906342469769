import NavSidebar from 'components/NavSidebar/NavSidebar';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TransactionLoader } from 'ui/TransactionLoader/TransactionLoader';
import { getOrdersByListingIdReq2 } from 'context/apis/api';
import styles from "./ListingOrder.module.css";
import TableSkeltonLoader from 'ui/TableSkeltonLoader/TableSkeltonLoader';
import TransactionTable from 'screens/Transactions/TransactionTable';
import { Pagination, Stack } from '@mui/material';

const ListingOrders = () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [allInvestments, setAllInvestments] = useState([]);
      const [page, setPage] = useState(1);
          const [totalPage, setTotalPage] = useState("");

    const getAllOrders = async() => {
        try {
            setLoading(true);
            const res = await getOrdersByListingIdReq2(id);
                    if (res?.status === 200) {
                      setAllInvestments(res?.data?.result);
                      setTotalPage(res?.data?.pages);
                    }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }


    const pageChangeHandler = (e, val) => {
      setPage(val);
    };

    useEffect(() => {
      getAllOrders();
    }, [page]);
  return (
    <NavSidebar>
      <div className={styles.wrapper}>
        <div className={styles.topSection}>
          {/* <div className={styles.searchContainer}>
            <img src={searchIcon} alt="search" className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Enter User Name or Email"
              className={styles.searchInput}
              id="searchInput"
              onChange={(e) => searchItems(e.target.value)}
            />
          </div> */}

          {/* <select onChange={changeStatusHandler} className={styles.selectContainer} name="status" id="status">
            <option value="">All</option>
            <option value="ACTIVE">Active</option>
            <option value="EXPIRE">Expire</option>
            <option value="PAID">Paid</option>
          </select> */}
        </div>
        {loading ? (
          <TableSkeltonLoader countRow={5} />
        ) : (
          <div className={styles.scroll}>
            <table className={styles.table}>
              <thead className={styles.tableHead}>
                <tr>
                  {/* <th className={styles.tableH}>S. No</th> */}
                  <th className={styles.tableH}>S. No.</th>
                  <th className={styles.tableH}>User Name</th>
                  <th className={styles.tableH}>User Email</th>
                  <th className={styles.tableH}>Listing ISIN</th>
                  <th className={styles.tableH}>Listing Company</th>
                  <th className={styles.tableH}>Total Amount</th>
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {allInvestments?.map((data, index) => {
                  return (
                    <TransactionTable key={index} data={data} index={index} type="listing" />
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className="d-flex justify-content-end mb-5 mt-5">
          <Stack spacing={2}>
            <Pagination
              page={Number(page)}
              onChange={pageChangeHandler}
              count={Number(totalPage)}
              shape="rounded"
            />
          </Stack>
        </div>
      </div>
    </NavSidebar>
  );
}

export default ListingOrders;