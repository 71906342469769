import React, { useState, useEffect } from "react";
import searchIcon from "../../assets/images/searchIcon.svg";
import "../User/unitdetails.css";
import {
    GetApplied,
  GetNotification,
} from "../../context/apis/api";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableSkeltonLoader from "../../ui/TableSkeltonLoader/TableSkeltonLoader";
import NavSidebar from "../../components/NavSidebar/NavSidebar";
import NTable from "components/UTable/NotificationTable";
import JTable from "components/UTable/JTable";


const JobApplication = () => {
  const [UserData, setUserData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [searchItem, setSearchItem] = useState("");

  const getAllUser = async () => {
    setLoading(true);
    try {
      const data = await GetApplied(page);
      setLoading(false);
      setUserData(data?.data.data);
      setTotalPage(data?.data?.pages);
      setPage(data?.data?.page)
      setShow(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const pageChangeHandler = (e, val) => {
    setPage(val);
  };

  useEffect(() => {
    getAllUser();
  }, [page]);

  return (
    <NavSidebar>
      <div className="unitdetails-container">
        <div className="unitdetails-firstSection">
          {/* <div className="unitdetails-searchDiv">
            <img src={searchIcon} alt="search" className="searchIcon" />
            <input
              type="text"
              placeholder="Enter a Name, Email or Mobile"
              className="unitdetails-searchInput"
              id="searchInput"
              onChange={(e) => searchItems(e.target.value)}
            />
          </div>
          <button className="btn-primary-admin" onClick={() => setShow(true)}>
            <Add /> Add Notification
          </button> */}
        </div>
        {loading ? (
          <TableSkeltonLoader countRow={7} />
        ) : (
          <div className="unitdetails-tableSection">
            {searchItem?.length > 0 ? (
              <JTable UserData={searchData} />
            ) : (
              <JTable UserData={UserData} />
            )}
          </div>
        )}
        <div className="d-flex justify-content-end mb-5">
          <Stack spacing={2}>
            <Pagination
              page={Number(page)}
              onChange={pageChangeHandler}
              count={Number(totalPage)}
              shape="rounded"
            />
          </Stack>
        </div>
      </div>
    </NavSidebar>
  );
};
export default JobApplication;
