import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
let year = new Date().getFullYear() + 1;
console.log(year);
let CMonth = new Date().getMonth();
console.log(CMonth);
const BarCharts = ({ sales }) => {
  let YTDMonths = [];
  let arr = [
    "Jan ",
    "Feb ",
    "Mar ",
    "Apr ",
    "May ",
    "Jun ",
    "Jul ",
    "Aug ",
    "Sep ",
    "Oct ",
    "Nov ",
    "Dec ",
  ];
  let Months = [
    `Jan ${year} `,
    `Feb ${year}`,
    `Mar ${year}`,
    `Apr ${year}`,
    `May ${year}`,
    `Jun ${year}`,
    `Jul ${year}`,
    `Aug ${year}`,
    `Sep ${year}`,
    `Oct ${year}`,
    `Nov ${year}`,
    `Dec ${year}`,
  ];

  Months.length = CMonth;
  function assign(arr, YTDMonths, k) {
    for (let i = 0; i < 12; ++i) {
      if (i < k) {
        YTDMonths.push(arr[i] + `${year}`);
      } else {
        YTDMonths.push(arr[i] + `${year - 1}`);
      }
    }
  }
  function rotate(arr, k) {
    for (let i = 0; i < k; ++i) {
      let s = arr[arr.length - 1];
      for (let j = arr.length - 1; j > 0; j--) {
        arr[j] = arr[j - 1];
      }
      arr[0] = s;
    }
  }

  assign(arr, YTDMonths, CMonth);
  rotate(YTDMonths, 12 - CMonth);

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: false,
      title: {
        display: true,
        text: "",
      },
    },
    responsive: true,
  };
  const [data] = useState({
    labels: YTDMonths,

    datasets: [
      {
        label: "",
        backgroundColor: "#0ebb1f",

        borderRadius: 5,
        data: sales,
      },
    ],
  });

  // console.log(Sales);
  // console.log(Purchases);

  return (
      <Bar data={data} options={options} />

  );
};

export default BarCharts;
