import axios from "axios";
import Cookies from "js-cookie";

const NewAPI = axios.create({
  baseURL: "https://unicap-yhef.onrender.com",
  // baseURL: "http://localhost:2222",
  headers: {
    Authorization: `Bearer ${Cookies.get("unicapAdminToken")
      ?.replace(`"`, "")
      ?.replace(`"`, "")}`,
  },
});

//login admin
export const AdminSignIn = (data) => NewAPI.post("/api/admin/login", data);
//get users and raiser with details
export const GetUsers = (page, status) =>
  NewAPI.get(`/api/admin/users?pageNumber=${page}&kyc=${status}`);
  export const GetRaiserUsers = (page, status) =>
    NewAPI.get(`/api/admin/raisers?pageNumber=${page}&kyc=${status}`);
export const GetNotification = (page) =>
  NewAPI.get(`/api/admin/getNotifications`);
export const GetApplied = (page) => NewAPI.get(`/api/admin/getApplicants?page=${page}`);
export const RaiserEditListing = (data, e) =>
  NewAPI.patch(`/api/admin/listing/update/${e}`, data);
export const addNotification = (body) =>
  NewAPI.post(`/api/admin/addNotification`, body);
export const updateNotification = (body) =>
  NewAPI.patch(`/api/admin/updateNotification`, body);
export const deleteNotification = (body) =>
  NewAPI.post(`/api/admin/deleteNotification`, body);
export const sendEmailApi = (body) => NewAPI.post(`/api/admin/sendEmail`, body);
export const getRaiserDetailsData = (id) =>
  NewAPI.get(`/api/admin/raisers/raiser/${id}`);
export const getUserDetailsData = (id) =>
  NewAPI.get(`/api/admin/users/user/${id}`);
//kyc
export const updateKycStatus = (id, action, reason) =>
  NewAPI.patch(`/api/admin/users/kyc/${id}?action=${action}&reason=${reason}`);
export const updateKycStatusRaiser = (id, action, reason) =>
  NewAPI.patch(
    `/api/admin/raisers/kyc/${id}?action=${action}&reason=${reason}`
  );
export const updateListingStatusRaiser = (id, action, reason, commision) =>
  NewAPI.patch(
    `/api/admin/raisers/listing/approve/${id}?action=${action}&reason=${reason}&commision=${commision}`
  );
// assets

export const searchUser = (search, page) =>
  NewAPI.get(`/api/admin/users/search?search=${search}&pageNumber=${page}`);
export const searchRaiser = (search, page) =>
  NewAPI.get(`/api/admin/raisers/search?search=${search}&pageNumber=${page}`);

//approved lisitng
export const getApprovedListingReq = (status, page) =>
  NewAPI.get(`/api/admin/listings/${status}/?pageNumber=${page}`);
export const searchApprovedListingReq = (search, page) =>
  NewAPI.get(
    `/api/admin/listings/search?search=${search}&pageNumber=${page}&status=approved`
  );
export const getApprovedListingDetailReq = (id) =>
  NewAPI.get(`/api/admin/listings/listing/${id}`);

//unapproved Lisitng
export const getUnApprovedListing = (status, page) =>
  NewAPI.get(`/api/admin/listings/${status}/?pageNumber=${page}`);
export const searchUnApprovedListingReq = (search, page) =>
  NewAPI.get(
    `/api/admin/listings/search?search=${search}&pageNumber=${page}&status=pending`
  );
export const getUnApprovedListingDetailReq = (id) =>
  NewAPI.get(`/api/admin/listings/listing/${id}`);

//transactions
export const getAllTransactionsReq = (page, userId, status, search) =>
  NewAPI.get(
    `/api/admin/orders/?pageNumber=${page}&userId=${userId}&status=${status}&search=${search}`
  );

export const getOrdersByListingIdReq = (id, type, userId) =>
  NewAPI.get(
    `/api/admin/orderByListingId?listingId=${id}&type=${type}&userId=${userId}`
  );

export const getOrdersByListingIdReq2 = (id) =>
  NewAPI.get(`/api/admin/listingOrders?listingId=${id}`);

//subadmin
export const addSubAdmin = (formData) =>
  NewAPI.post("/api/admin/subadmin/register", formData);
export const getAllSubAdmin = (page) =>
  NewAPI.get(`/api/admin/subadmin?pageNumber=${page}`);
export const searchSubAdmin = (search, page) =>
  NewAPI.get(`/api/admin/subadmin/search?search=${search}&pageNumber=${page}`);
export const deleteSubAdmin = (id) =>
  NewAPI.delete(`/api/admin/subadmin/delete/${id}`);
export const editSubAdmin = (id, formData) =>
  NewAPI.patch(`/api/admin/subadmin/update/${id}`, formData);

export const addTagsHandlerReq = (id, data) =>
  NewAPI.patch(`api/admin/listing/update/${id}`, data);
