import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Transactions.module.css";


const TransactionTable = ({data, index, type }) => {
  const history = useHistory();
  return (
    <tr
      onClick={() =>
        history.push(`/investments/${data?.listingId?._id}`, { data })
      }
      className={styles.tdContainer}
    >
      <td className={styles.tableData}>{index + 1}</td>
      <td className={styles.tableData}>{data?.userId?.name}</td>
      <td className={styles.tableData}>{data?.userId?.email}</td>
      <td className={styles.tableData}>{data?.listingId?.ISIN}</td>
      <td className={styles.tableData}>{data?.listingId?.companyName}</td>
      {type === "user" && (
        <>
          <td className={styles.tableData}>₹ {data?.returns}</td>
          <td className={styles.tableData}>{data?.avgTaxReturns} %</td>
        </>
      )}
      <td className={styles.tableData}>₹ {data?.total}</td>
    </tr>
  );
};

export default TransactionTable;
