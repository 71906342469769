import React, { useState } from "react";
import "./table.css";
import NTableRow from "./NTableRow";
import { Modal } from "react-bootstrap";

const NTable = ({ UserData }) => {

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Title</th>
            <th>Description</th>
            <th>Time</th>
            <th style={{ borderRight: "none" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {UserData &&
            UserData.map((item, index) => {
              return (
                <NTableRow
                  id={item._id}
                  key={index}
                  index={index}
                  title={item.title}
                  description={item.description}
                  time={item.createdAt}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default NTable;
