import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useStyles from "./styles";
import "./NavSidebar.css";
import logo from "../../assets/images/logo.svg";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TransformIcon from "@mui/icons-material/Transform";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { AuthContext } from "../../context/actions/AuthContext";
import { LOGOUT } from "../../context/types/types";
import { Notifications } from "@mui/icons-material";
import CircularLoader from "ui/CircularLoader/CircularLoader";
import { Modal } from "react-bootstrap";

const drawerWidth = 250;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#fff",
  color: "white",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiTypography-body1": {
    fontFamily: "Inter !important",
  },
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    fontFamily: "Inter",
    backgroundColor: "#1D4442",
    color: "white",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

const mdTheme = createTheme();

const NavSidebar = (props) => {
  const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  const { dispatch, setCurrentUser, currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [role, setRole] = useState(localStorage.getItem("unicapUserRole"));
  const [tabList, setTabList] = useState(
    currentUser?.user?.tags ||
      JSON.parse(localStorage.getItem("unicapUserTabs"))
  );

  const history = useHistory();

  const handleLogout = () => {
    dispatch({
      type: LOGOUT,
      history,
      setCurrentUser,
    });
    localStorage.clear();
    history.push("/");
  };

  const handleListClick = (url) => {
    history.push(url);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar>
            {!open && (
              <div
                style={{ margin: 0 }}
                onClick={toggleDrawer}
                className={classes.arrowContainer}
              >
                <ArrowCircleRightIcon
                  style={{ width: "100%", height: "100%", color: "#E9ECE0" }}
                />
              </div>
            )}
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {/**Dashboard */}
            </Typography>
            <div className={classes.logoutBtnDiv}>
              <button className={classes.logoutBtn} onClick={handleShow}>
                Log out
              </button>
            </div>
          </Toolbar>
          <Modal size="md" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Logout Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="fs-5">Are you sure you want to logout?</div>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleClose}
                className="btnPrimary"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #1D4442",
                  color: "#1D4442",
                }}
              >
                Cancel
              </button>
              <button
                className="btnPrimary"
                variant="danger"
                classId="deleteConfimationButton"
                onClick={handleLogout}
              >
                Logout
              </button>
            </Modal.Footer>
          </Modal>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
              border: "2px solid white",
              backgroundColor: "white",
            }}
          >
            <div
              className="navbar-logo-container"
              style={{ backgroundColor: "white", border: "2px solid white" }}
            >
              <img src={logo} alt="logo"></img>
            </div>
            <div onClick={toggleDrawer} className={classes.arrowContainer}>
              {open && (
                <ArrowCircleLeftIcon
                  style={{ width: "100%", height: "100%", color: "#E9ECE0" }}
                />
              )}
            </div>
          </Toolbar>
          <Divider />

          {role === "admin" ? (
            <List className={classes.listDiv}>
              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/users")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/users")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes("/users") ? (
                    <PeopleAltIcon
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <PeopleAltIcon
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>

              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/raisers")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/raisers")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes("/raisers") ? (
                    <PersonIcon
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <PersonIcon
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Raisers" />
              </ListItem>

              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/unapproved-listing")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/unapproved-listing")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes(
                    "/unapproved-listing"
                  ) ? (
                    <PlaylistRemoveIcon
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <PlaylistRemoveIcon
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Unapproved Listings" />
              </ListItem>

              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/approved-listing")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/approved-listing")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes("/approved-listing") ? (
                    <PlaylistAddCheckIcon
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <PlaylistAddCheckIcon
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Approved Listings" />
              </ListItem>

              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/investments")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/investments")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes("/investments") ? (
                    <TransformIcon
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <TransformIcon
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Investments" />
              </ListItem>

              {/* <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/investments")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/investments")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes("/investments") ? (
                      <PaymentIcon style={{ color: "white", fontSize: "1.8rem" }} />
                    ) : (
                      <PaymentIcon style={{ color: "white", fontSize: "1.8rem" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Investments" />
                </ListItem>
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/transactions")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/transactions")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes("/transactions") ? (
                      <TransformIcon style={{ color: "white", fontSize: "1.8rem" }} />
                    ) : (
                      <TransformIcon style={{ color: "white", fontSize: "1.8rem" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Transactions" />
                </ListItem> */}
              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/sub-admins")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/sub-admins")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes("/sub-admins") ? (
                    <AdminPanelSettingsIcon
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <AdminPanelSettingsIcon
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Sub Admins" />
              </ListItem>
              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/notification")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/notification")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes("/notification") ? (
                    <Notifications
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <Notifications
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItem>

              <ListItem
                button
                className={
                  window?.location?.pathname?.includes("/applied-position")
                    ? classes.selectedList
                    : ""
                }
                onClick={() => handleListClick("/applied-position")}
              >
                <ListItemIcon>
                  {window?.location?.pathname?.includes("/applied-position") ? (
                    <TransformIcon
                      style={{ color: "#1D4442", fontSize: "1.8rem" }}
                    />
                  ) : (
                    <TransformIcon
                      style={{ color: "white", fontSize: "1.8rem" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Job Applications" />
              </ListItem>
            </List>
          ) : (
            <List className={classes.listDiv}>
              {tabList?.includes("users") && (
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/users")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/users")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes("/users") ? (
                      <PeopleAltIcon
                        style={{ color: "#1D4442", fontSize: "1.8rem" }}
                      />
                    ) : (
                      <PeopleAltIcon
                        style={{ color: "white", fontSize: "1.8rem" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              )}
              {tabList?.includes("raisers") && (
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/raisers")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/raisers")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes("/raisers") ? (
                      <PersonIcon
                        style={{ color: "#1D4442", fontSize: "1.8rem" }}
                      />
                    ) : (
                      <PersonIcon
                        style={{ color: "white", fontSize: "1.8rem" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Raisers" />
                </ListItem>
              )}
              {tabList?.includes("unapproved-listing") && (
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/unapproved-listing")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/unapproved-listing")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes(
                      "/unapproved-listing"
                    ) ? (
                      <PlaylistRemoveIcon
                        style={{ color: "#1D4442", fontSize: "1.8rem" }}
                      />
                    ) : (
                      <PlaylistRemoveIcon
                        style={{ color: "white", fontSize: "1.8rem" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="UnApproved Listing" />
                </ListItem>
              )}
              {tabList?.includes("approved-listing") && (
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/approved-listing")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/approved-listing")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes(
                      "/approved-listing"
                    ) ? (
                      <PlaylistAddCheckIcon
                        style={{ color: "#1D4442", fontSize: "1.8rem" }}
                      />
                    ) : (
                      <PlaylistAddCheckIcon
                        style={{ color: "white", fontSize: "1.8rem" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Approved Listing" />
                </ListItem>
              )}

              {tabList?.includes("sub-admins") && (
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/sub-admins")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/sub-admins")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes("/sub-admins") ? (
                      <AdminPanelSettingsIcon
                        style={{ color: "#1D4442", fontSize: "1.8rem" }}
                      />
                    ) : (
                      <AdminPanelSettingsIcon
                        style={{ color: "white", fontSize: "1.8rem" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Sub Admins" />
                </ListItem>
              )}

              {tabList?.includes("investments") && (
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/investments")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/investments")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes("/investments") ? (
                      <TransformIcon
                        style={{ color: "#1D4442", fontSize: "1.8rem" }}
                      />
                    ) : (
                      <TransformIcon
                        style={{ color: "white", fontSize: "1.8rem" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Investments" />
                </ListItem>
              )}

              {tabList?.includes("applied-position") && (
                <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/applied-position")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/applied-position")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes(
                      "/applied-position"
                    ) ? (
                      <PersonIcon
                        style={{ color: "#1D4442", fontSize: "1.8rem" }}
                      />
                    ) : (
                      <PersonIcon
                        style={{ color: "white", fontSize: "1.8rem" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Job Applications" />
                </ListItem>
              )}

              {/* <ListItem
                  button
                  className={
                    window?.location?.pathname?.includes("/investments")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/investments")}
                >
                  <ListItemIcon>
                    {window?.location?.pathname?.includes("/investments") ? (
                      <PaymentIcon style={{ color: "white", fontSize: "1.8rem" }} />
                    ) : (
                      <PaymentIcon style={{ color: "white", fontSize: "1.8rem" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Investments" />
                </ListItem> */}
            </List>
          )}
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              window?.location?.pathname?.includes("/add")
                ? "#fff"
                : theme.palette.grey[100],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default NavSidebar;
