import BarChart from 'components/BarChart/BarChart';
import NavSidebar from 'components/NavSidebar/NavSidebar';
import { getOrdersByListingIdReq } from 'context/apis/api';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from "./TransactionDetail.module.css";
import { TransactionLoader } from 'ui/TransactionLoader/TransactionLoader';
import Graph from "../../assets/images/graph.png";
import moment from 'moment';

const TransactionDetail = () => {

    const {listingId} = useParams();
    const [graph, setGraph] = useState([]);
    const [listingDetail, setListingDetail] = useState(null);
    const [investedAmount, setInvestedAmount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [orderType, setOrderType] = useState("1y");
    const {state} = useLocation();

    const getOrderByListingId = async() => {
        try {
            setLoading(true);
            const res = await getOrdersByListingIdReq(listingId, orderType, state?.data?.userId?._id);
            console.log(res);
            if(res?.data?.success) {
                setGraph(res?.data?.result);
                setListingDetail(res?.data?.listing);
                setInvestedAmount(res?.data?.order);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        getOrderByListingId();
    }, [state, orderType])


  return (
    <NavSidebar>
      <div className={styles.wrapper}>
        {loading ? (
          <TransactionLoader />
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.chartContainer}>
                <div className={styles.firstSection}>
                  <div className={styles.firstSectionTop}>
                    <img
                      style={{
                        borderRadius: "6px",
                        width: "60px",
                        height: "60px",
                        marginRight: "20px",
                      }}
                      src={listingDetail?.companyLogo?.url}
                      alt="card"
                      width="100%"
                    />
                    <h4>{listingDetail?.companyName}</h4>
                  </div>
                </div>
                {/* <div className={styles.filters}>
                  <span
                    onClick={() => setOrderType("1d")}
                    className={
                      orderType === "1d" ? styles.active : styles.filterSpan
                    }
                  >
                    1d
                  </span>
                  <span
                    onClick={() => setOrderType("7d")}
                    className={
                      orderType === "7d" ? styles.active : styles.filterSpan
                    }
                  >
                    7d
                  </span>
                  <span
                    onClick={() => setOrderType("1m")}
                    className={
                      orderType === "1m" ? styles.active : styles.filterSpan
                    }
                  >
                    1m
                  </span>
                  <span
                    onClick={() => setOrderType("6m")}
                    className={
                      orderType === "6m" ? styles.active : styles.filterSpan
                    }
                  >
                    6m
                  </span>
                  <span
                    onClick={() => setOrderType("1y")}
                    className={
                      orderType === "1y" ? styles.active : styles.filterSpan
                    }
                  >
                    1y
                  </span>
                </div> */}
                <BarChart sales={graph} />
              </div>

              <div className={styles.rightContainer}>
                <div className={styles.card}>
                  <div className="d-flex justify-content-between w-100">
                    <div style={{ width: "60px" }}>
                      <img
                        src={listingDetail?.companyLogo?.url}
                        alt="card"
                        width="100%"
                      />
                    </div>
                    <h5>{listingDetail?.category}</h5>
                  </div>

                  <h4> {listingDetail?.companyName}</h4>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Invested</h6>
                      <h6>₹ {investedAmount?.total}</h6>
                      {/* <p>+7657</p> */}
                    </div>
                    <div className="mt-5">
                      <img src={Graph} alt="graph" />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className={styles.balances}>
                    <div>
                      <h3>Total Invested</h3>
                      <h4>₹ {investedAmount?.total}</h4>
                    </div>
                    <div>
                      <h3>Total Returns</h3>
                      <h5>
                        ₹ {investedAmount?.returns}
                        <br />
                        <small>{investedAmount?.avgTaxReturns} % </small>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className={styles.balances}>
                <div className="justify-content-center">
                  <h3>Invested Amount</h3>
                </div>
                {investedAmount?.investments?.map((item, id) => {
                  return (
                    <div key={item._id}>
                      <h3>
                        {String(investedAmount?._id)
                          .substring(String(investedAmount?._id).length - 6)
                          ?.toUpperCase()}
                      </h3>
                      <h5>
                        ₹ {item?.amount}
                      </h5>
                      <h5>{moment(item?.paymentDate).format("DD-MM-YYYY")}</h5>
                    </div>
                  );
                })}
              </div>
          </>
        )}
      </div>
    </NavSidebar>
  );
}

export default TransactionDetail;