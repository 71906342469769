import React from "react";
import styles from "./UtableRow.module.css";
import { Delete } from "@mui/icons-material";
import { deleteNotification } from "context/apis/api";

const NTableRow = ({ id, index, title, description, time }) => {
  const deleteHandler = async () => {
    const yes = window.confirm(
      "Are you sure you want to delete this notification?"
    );
    if (yes) {
      await deleteNotification({
        id: id,
      });
      window.location.reload();
      // Delete the notification
    }
  };
  return (
    <>
      <tr className={styles.tableRow}>
        <td>{index + 1}</td>
        <td>{title}</td>
        <td>{description}</td>
        <td>{new Date(time).toLocaleString()}</td>
        <td onClick={deleteHandler}>
          <Delete color="error" />
        </td>
      </tr>
    </>
  );
};

export default NTableRow;
