import React from 'react'
import { useHistory } from 'react-router-dom';
import styles from "./UnApprovedListing.module.css";
const UnApprovedLisitingTable = ({data}) => {
    const history = useHistory();

  const openListingHandler = (id) => {
    history.push(`/unapproved-listing/${id}`)
  }

  return (
    <tr onClick={()=>openListingHandler(data?._id)} className={styles.tdContainer}>
    <td className={styles.tableData}>{data?.ISIN}</td>
    <td className={styles.tableData}>
      <img
        style={{ width: "70px" }}
        src={data?.companyLogo?.url}
        alt=""
      />
    </td>
    <td className={styles.tableData}>{data?.companyName}</td>
    <td className={styles.tableData}>{data?.raiserId?.name}</td>
    <td className={styles.tableData}>{data?.raiserId?.email}</td>
    {/* <td className={styles.tableData}>
      {data?.isVerified ? "Yes" : "No"}
    </td> */}
    <td className={styles.tableData}>
      {(data?.status === "pending" || data?.status === null) && (
        <div style={{ display: "flex" }}>
        <span style={{ color: "red" }}>Pending</span>
          {/* <Button onClick={()=>approveListingHandler(data?._id)} style={{marginRight: '10px'}} variant="contained">Approve</Button>
                            <Button onClick={()=>{handleOpen2(); setListingId(data?._id)}}>Reject</Button> */}
        </div>
      )}
      {data?.status === "rejected" && (
        <span style={{ color: "red" }}>Rejected</span>
      )}
      {data?.status === "approved" && (
        <span style={{ color: "green" }}>Approved</span>
      )}
    </td>
  </tr>
  )
}

export default UnApprovedLisitingTable