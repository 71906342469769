import Cookies from "js-cookie";
import { AdminSignIn } from "../apis/api";

export const userLogin = async (
    loginData,
    setLoading,
    history,
    setCurrentUser
  ) => {
    setLoading(true);
    try {
      const { data } = await AdminSignIn(loginData);
      setLoading(false);
      Cookies.set("unicapAdminToken", JSON.stringify(data?.token))
      setCurrentUser({
        id: data?.token,
        user: data?.data?.user
      });
      localStorage.setItem("unicapUserRole", data?.data?.user?.role);
      localStorage.setItem("unicapUserTabs", JSON.stringify(data?.data?.user?.tags))
      window.location.replace("/users");
    } catch (err) {
      setLoading(false);
      alert("Wrong email or password!!");
    }
  };

  export const userLogout = async (
    history,
    setCurrentUser
  ) => {
      setCurrentUser({
        id: null,
        user: null
      });
      Cookies.remove("unicapAdminToken");
      history.push("/");
  };