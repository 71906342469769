import "./TransactionLoader.css";
export function TransactionLoader() {
  const COUNTER = 1;
  const CourseSkelton = () => (
    <div className="card-container-skelton">
      <div className="card-second"></div>
      <div className="card-third-cont">
        <div className="card-third"></div>
        <div className="card-third"></div>
      </div>
    </div>
  );
  return Array(COUNTER).fill(<CourseSkelton key={Math.random()} />);
}
