import { Pagination, Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {getUnApprovedListing, searchUnApprovedListingReq } from "../../context/apis/api";
import TableSkeltonLoader from "../../ui/TableSkeltonLoader/TableSkeltonLoader";
import searchIcon from "../../assets/images/searchIcon.svg";
import styles from "./UnApprovedListing.module.css"
import NavSidebar from "../../components/NavSidebar/NavSidebar";
import UnApprovedLisitingTable from "./UnApprovedListingTable";

const UnApprovedListing = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [listings, setListings] = useState([]);
  const [searchListings, setSearchListings] = useState([]);
  const [searchItem, setSearchItem] = useState("");

  const getApprovedListing = async() => {
    setLoadingPage(true);
    try {
      const res = await getUnApprovedListing("pending", page);
      if(res?.status === 200){
        setListings(res?.data?.listings);
        setTotalPage(res?.data?.pages);
      }
      setLoadingPage(false);
    } catch (error) {
      console.log(error);
      setLoadingPage(false);
    }
  }

  const searchItems = async(searchValue) => {
    setSearchItem(searchValue);
    try {
      setLoadingPage(true);
      const res = await searchUnApprovedListingReq(searchValue, page);
      if(res?.status === 200){
        setSearchListings(res?.data?.result);
        setTotalPage(res?.data?.pages);
      }
      setLoadingPage(false);
    } catch (error) {
      console.log(error);
      setLoadingPage(false);
    }
  };

  const pageChangeHandler = (e, val) => {
    setPage(val);
  }

  useEffect(() => {
    getApprovedListing();
  }, [page])


  return (
    <NavSidebar>
    <div className={styles.wrapper}>
    <div className={styles.topSection}>
          <div className={styles.searchContainer}>
            <img src={searchIcon} alt="search" className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Enter a Company Name"
              className={styles.searchInput}
              id="searchInput"
              onChange={(e) => searchItems(e.target.value)}
            />
          </div>
        </div>
      {
        loadingPage ? (
          <TableSkeltonLoader countRow={5}/>
        ) : (

        <div className={styles.scroll}>
          <table className={styles.table}>
            <thead className={styles.tableHead}>
            <tr>

              {/* <th className={styles.tableH}>S. No</th> */}
              <th className={styles.tableH}>ISIN</th>
              <th className={styles.tableH}>Company Logo</th>
              <th className={styles.tableH}>Company Name</th>
              <th className={styles.tableH}>Raiser</th>
              <th className={styles.tableH}>Raiser Email</th>
              {/* <th className={styles.tableH}>Verified</th> */}
              <th className={styles.tableH}>KYC</th>
            </tr>
            </thead>
            <tbody className={styles.tableBody}>
            {
              searchItem.length > 0 ? (
                searchListings?.map((data, index) => {
                  return (
                    <UnApprovedLisitingTable key={index} data={data}/>
                  );
                })
              ) : (
                listings?.map((data, index) => {
                  return (
                    <UnApprovedLisitingTable key={index} data={data}/>
                  );
                })
              )
            }
            </tbody>
          </table>
        </div>
        )
      }
      <div className="d-flex justify-content-end mb-5 mt-5">
          <Stack spacing={2}>
            <Pagination page={Number(page)} onChange={pageChangeHandler} count={Number(totalPage)} shape="rounded" />
          </Stack>
          </div>
    </div>
    </NavSidebar>
  );
};


export default UnApprovedListing;