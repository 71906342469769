import ListingDetail from "components/ListingDetail/ListingDetail";
import NavSidebar from "components/NavSidebar/NavSidebar";
import { getApprovedListingDetailReq } from "context/apis/api";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "ui/ListingSkelton/ListingSkelton";
import styles from "./ApprovedListingDetails.module.css";

const ApprovedListingDetails = () => {
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(false);

  const getApprovedListingById = async () => {
    try {
      setLoading(true);
      const res = await getApprovedListingDetailReq(listingId);
      if (res?.data?.success) {
        setListing(res?.data?.listing);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getApprovedListingById();
  }, [listingId]);
  return (
    <NavSidebar>
      <div className={styles.wrapper}>
        {
          loading ? (
            <Skeleton />
          ) : (
            <ListingDetail data={listing} isApproved={true}/>
          )
        }
      </div>
    </NavSidebar>
  );
};

export default ApprovedListingDetails;
