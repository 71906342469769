import { createContext, useReducer, useState } from "react";
import { userLogin, userLogout } from "../reducers/AuthReducer";
import { LOGIN, LOGOUT } from "../types/types";
export const AuthContext = createContext();


const AuthContextComponent = ({children}) => {
    const [currentUser, setCurrentUser] = useState({
        id: null,
        user: null
      });

      const reducer = (state, action) => {
        switch (action.type) {
            case LOGIN:
              userLogin(action.loginData, action.setLoading, action.history, action.setCurrentUser);
            break;  

            case LOGOUT:
              userLogout(action.history, action.setCurrentUser);
            break;

            default:
                break;
        }
      }

      const initialValues = {};
      const [state, dispatch] = useReducer(reducer, initialValues);

      const values = {
        dispatch,
        currentUser,
        setCurrentUser
      };


  return (
    <AuthContext.Provider value={values}>
        {children}
    </AuthContext.Provider>
  )
}

export default AuthContextComponent;