import "./ListingSkelton.css";

export default function Skeleton({ type }) {
  const COUNTER = 1;
  const ListingSKelton = () => (
    <div className="card-container-skelton3" style={{ cursor: "pointer" }}>
      <div className="skelDivContainer">
        <div className="skel1div">
          <div className="skel1div1"></div>
          <div className="skel1div2">
            <div className="skel1div21"></div>
            <div className="skel1div21"></div>
          </div>
        </div>
        <div className="skel2div">
          <div className="skel2div1"></div>
          <div className="skel2div2"></div>
          <div className="skel2div1"></div>
        </div>
      </div>

      <div className="middleSkel">
        <div className="middleSkel1">
          <div className="middleSkel11"></div>
          <div className="middleSkel12"></div>
        </div>
        <div className="middleSkel1">
        <div className="middleSkel11"></div>
          <div className="middleSkel12"></div>
        </div>
        <div className="middleSkel1">
        <div className="middleSkel11"></div>
          <div className="middleSkel12"></div>
        </div>
      </div>
    </div>
  );
  return Array(COUNTER).fill(<ListingSKelton />);
}
