import React, { useState, useEffect } from "react";
import { RaiserEditListing } from "context/apis/api";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export const EditModal = ({ list, isEdit, setIsEdit }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [input, setInput] = useState({
    companyName: "",
    companyDescription: "",
    assetsDetail: "",
    category: "Corporate Bonds",
    industry: "",
    unitEconomicsBuisnessModel: "",
    minimumInvestment: "",
    faceValue: "",
    preTaxReturns: "",
    dateOfIssue: "",
    maturityDate: "",
    ISIN: "", //should be of length 12
    transactionType: "",
    couponRate: "",
    aboutSeller: "",
    riskFactors: "",
    exitOptions: "",
    FAQs: "",
    maximumReturn: "Market Linked",
    companyPreference: "Startups",
    opportunity: "Equity",
    passive: "Private",
    defence: "Preleased rental",
    market: "Worldwide",
    timeLine: "<12months",
    totalInvestment: "",
    currentInvestedAmount: "0",
    timeToMaturity: "",
    comments: "",
  });
  useEffect(() => {
    if (list) {
      setInput(list);
    }
  }, [list]);
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const isApproved =
        location.pathname.split("/")[1] === "approved-listing" ? true : false;
      const { data } = await RaiserEditListing(
        {
          ...input,
          isVerified: isApproved ? true : false,
          status: isApproved ? "approved" : "pending",
          reason: "",
        },
        list._id
      );
      console.log(data.message);
      setLoading(false);
      setIsEdit(false);
      window.location.reload();
      alert("Listing Updated Successfully");
      //   toast.success(data.message, ToastOptions);
      //   navigate("/my-listing");
    } catch (error) {
      const { response } = error;
      console.log(response.data.message);
      setLoading(false);
      //   toast.error(response.data.message, ToastOptions);
    }
  };
  return (
    <div>
      <Modal
        size="xl"
        show={isEdit}
        style={{ zIndex: "9999" }}
        onHide={() => setIsEdit(false)}
        centered
      >
        <Modal.Header closeButton onClick={() => setIsEdit(false)}>
          <h4 className="fw-bold">Edit Listing</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form
              className="category-main p-x pt-1 container"
              onSubmit={handleSubmit}
            >
              <div className="d-flex mb-3">
                <Form.Group className="w-100">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    defaultValue={list?.companyName}
                    required
                    value={input.companyName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>

              <Form.Group className="mb-4">
                <Form.Label>Company Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  defaultValue={list?.companyDescription}
                  required
                  name="companyDescription"
                  value={input.companyDescription}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Assets Detail</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  defaultValue={list?.assetsDetail}
                  required
                  name="assetsDetail"
                  value={input.assetsDetail}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  defaultValue="Choose..."
                  name="category"
                  value={input.category}
                  onChange={handleChange}
                >
                  <option>Corporate Bonds</option>
                  <option>Leasing</option>
                  <option>Inventories</option>
                  <option>Startup Equity</option>
                  <option>Commercial Property</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Industry</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  required
                  name="industry"
                  value={input.industry}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Unit economics Businesss Model</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  required
                  name="unitEconomicsBuisnessModel"
                  value={input.unitEconomicsBuisnessModel}
                  onChange={handleChange}
                />
              </Form.Group>

              <Row className="mb-4">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Minimum Investment</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="minimumInvestment"
                    value={input.minimumInvestment}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Face Value</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="faceValue"
                    value={input.faceValue}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Pre Tax Returns</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="preTaxReturns"
                    value={input.preTaxReturns}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Date Of Issue</Form.Label>
                  <Form.Control
                    type="date"
                    required
                    name="dateOfIssue"
                    value={input.dateOfIssue?.slice(0, 10)}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Maturity Date</Form.Label>
                  <Form.Control
                    type="date"
                    required
                    name="maturityDate"
                    value={input.maturityDate?.slice(0, 10)}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>ISIN</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    minLength="12"
                    maxLength="12"
                    name="ISIN"
                    value={input.ISIN}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Transaction Type</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="transactionType"
                    value={input.transactionType}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Coupon Rate</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="couponRate"
                    value={input.couponRate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-4">
                <Form.Label>About the Sellers</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  required
                  name="aboutSeller"
                  value={input.aboutSeller}
                  onChange={handleChange}
                />
              </Form.Group>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Company Preference</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="companyPreference"
                    value={input.companyPreference}
                    onChange={handleChange}
                  >
                    <option>Startups</option>
                    <option>Mature Company</option>
                    <option>Any</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Opportunity</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="opportunity"
                    value={input.opportunity}
                    onChange={handleChange}
                  >
                    <option>Equity</option>
                    <option>Startup</option>
                    <option>Crypto</option>
                    <option>Collectibles</option>
                    <option>Invoice</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Passive</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="passive"
                    value={input.passive}
                    onChange={handleChange}
                  >
                    <option>Private</option>
                    <option>Bonds</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Defence</Form.Label>
                  <Form.Select
                    defaultValue="preleased rental"
                    name="defence"
                    value={input.defence}
                    onChange={handleChange}
                  >
                    <option>Preleased rental</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} className="mb-4">
                  <Form.Label>
                    Time To Maturity{" "}
                    <small style={{ fontSize: "11px" }}>(in months)</small>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="timeToMaturity"
                    value={input.timeToMaturity}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} className="mb-4">
                  <Form.Label>Market</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="market"
                    value={input.market}
                    onChange={handleChange}
                  >
                    <option>Worldwide</option>
                    <option>Domestic</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} className="mb-4">
                  <Form.Label>TimeLine</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="timeLine"
                    value={input.timeLine}
                    onChange={handleChange}
                  >
                    <option>{`<12months`}</option>
                    <option>1-3years</option>
                    <option>+3years</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} className="mb-4">
                  <Form.Label>Maximum Return</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    name="maximumReturn"
                    value={input.maximumReturn}
                    onChange={handleChange}
                  >
                    <option>Market Linked</option>
                    <option>Fixed Return</option>
                    <option>Combination</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} className="mb-4">
                  <Form.Label>Total Investment</Form.Label>
                  <Form.Control
                    type="number"
                    name="totalInvestment"
                    value={input.totalInvestment}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Row>
              <Form.Group className="mb-4">
                <Form.Label>Exit Options</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  name="exitOptions"
                  value={input.exitOptions}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Risk Factors</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  name="riskFactors"
                  value={input.riskFactors}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>FAQs</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  name="FAQs"
                  value={input.FAQs}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Additional Info</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  name="comments"
                  value={input.comments}
                  onChange={handleChange}
                />
              </Form.Group>
              <div className="d-flex w-100 justify-content-center">
                <button className="btnPrimary mx-4 " type="submit">
                  {loading ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </Form>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};
