import React, { useState } from "react";
import styles from "./ListingDetail.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import {
  RaiserEditListing,
  addTagsHandlerReq,
  updateListingStatusRaiser,
} from "context/apis/api";
import CircularLoader from "ui/CircularLoader/CircularLoader";
import ModalView from "ui/ModalView/ModalView";
import "react-tagsinput/react-tagsinput.css";
import { TagsInput } from "react-tag-input-component";
import { EditModal } from "./EditModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ListingDetail = ({ data, isApproved }) => {
  const history = useHistory();
  const params = useParams();
  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [reason, setReason] = useState("");
  const [commission, setCommission] = useState("");
  const [loading2, setIsLoading2] = useState(false);
  const [loading3, setIsLoading3] = useState(false);
  const [listingId, setListingId] = useState("");
  const [appId, setAppId] = useState(null);
  const [tags, setTags] = useState(data?.tags || []);
  const calcPercentage = () => {
    const Total = 100 / data?.totalInvestment;
    const percent = data?.currentInvestedAmount * Total;
    return String(percent).slice(0, 5);
  };

  const [isEdit, setIsEdit] = useState(false);

  const approveListingHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await updateListingStatusRaiser(appId, "approve", "", commission);
      if (res?.data?.success) {
        alert("Listing Approve Success");
        history.push("/unapproved-listing");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert(error?.response?.data?.message);
    }
  };

  const rejectListingHandler = async (e) => {
    e.preventDefault();
    setIsLoading2(true);
    try {
      const res = await updateListingStatusRaiser(listingId, "reject", reason);
      if (res?.data?.success) {
        alert("Listing Rejected Success");
        history.push("/unapproved-listing");
      }
      setIsLoading2(false);
    } catch (error) {
      setIsLoading2(false);
      alert(error?.response?.data?.message);
    }
  };

  const handleChangeTags = (tag) => {
    setTags([tag]);
  };

  console.log(data);
  const addTagsHandler = async () => {
    setIsLoading3(true);
    try {
      const res = await addTagsHandlerReq(data?._id, { tags });
      if (res?.data?.success) {
        alert("Tags Added!");
        history.push("/approved-listing");
      }
      setIsLoading3(false);
    } catch (error) {
      setIsLoading3(false);
      console.log(error);
    }
  };
  const dealClosed = async (status) => {
    const yes = window.confirm(
      `Are you sure ${status ? "close" : "enable"} this listing?`
    );
    if (!yes) return;
    const confirm = window.prompt("Please type 'yes' to confirm");
    if (confirm !== "yes") return;
    setIsLoading3(true);
    try {
      const { data } = await RaiserEditListing(
        {
          isClosed: status,
        },
        params.listingId
      );
      if (data?.success) {
        alert("Listing Updated");
        window.location.reload();
      }
      setIsLoading3(false);
    } catch (error) {
      setIsLoading3(false);
      console.log(error);
    }
  };

  return (
    <div className={styles.ccMain}>
      <div className="row">
        <div className="col-lg-2">
          <span
            title="go back"
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => history.goBack()}
          >
            <KeyboardBackspaceIcon size="28" />
          </span>
          <img src={data?.companyLogo?.url} alt="logo" width="80px" />
        </div>
        <div className="col-lg">
          <h3>{data?.companyName}</h3>
          <p>{data?.companyDescription?.slice(0, 200)}...</p>
        </div>
        <div className="col-lg-3 d-flex flex-column align-items-center justify-content-center">
          {!isApproved &&
            (data?.status === "pending" || data?.status === null) && (
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <Button
                  onClick={() => {
                    handleOpen3();
                    setAppId(data?._id);
                  }}
                  className={styles.btnPrimary}
                  variant="contained"
                  style={{
                    backgroundColor: "#1D4442",
                    boxShadow: "none",
                    marginRight: "10px",
                  }}
                >
                  Approve
                </Button>
                <Button
                  onClick={() => {
                    handleOpen2();
                    setListingId(data?._id);
                  }}
                  className={styles.btnSecondary}
                  style={{ color: "#1D4442" }}
                >
                  Reject
                </Button>
              </div>
            )}
          <div className="d-flex justify-content-center align-items-center">
            {data?.status === "rejected" && (
              <span style={{ color: "red", marginBottom: "10px" }}>
                Rejected
              </span>
            )}
            {data?.status === "approved" && (
              <span
                style={{
                  color: "green",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                Approved
              </span>
            )}
            <button
              className="btn btn-warning mx-2 my-2"
              onClick={() => setIsEdit(true)}
            >
              {/* <Edit /> */}
              {data?.status === "approved" ? "Edit" : "Edit"}
            </button>
            <button
              className={`${
                data?.isClosed ? "btn btn-success" : "btn btn-danger"
              }`}
              onClick={() => dealClosed(!data?.isClosed)}
            >
              {/* <Close /> */}
              {data?.isClosed ? "Enable" : "Close"}
            </button>
          </div>

          {isApproved && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {data?.tags?.map((item) => {
                  return (
                    <span
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        borderRadius: "6px",
                        color: "white",
                        padding: "2px 5px",
                        backgroundColor: "rgb(49, 100, 152, 0.33)",
                      }}
                    >
                      {item}
                    </span>
                  );
                })}
              </div>
              <Button
                onClick={handleOpen}
                variant="contained"
                className={styles.btnPrimary}
                style={{
                  marginBottom: "20px",
                  backgroundColor: "#1D4442",
                  boxShadow: "none",
                }}
              >
                Add Tags
              </Button>
              <Button
                onClick={() =>
                  history.push(`/approved-listing/investment/${data?._id}`)
                }
                variant="contained"
                className={styles.btnSecondary}
                style={{
                  marginBottom: "20px",
                  backgroundColor: "#1D4442",
                  boxShadow: "none",
                }}
              >
                View Investment
              </Button>
            </div>
          )}
          <h5>{data?.category}</h5>
          <div className={styles.progressMain}>
            <div className={styles.progressBg}>
              <div>
                <span>
                  <CircularProgressbar
                    value={calcPercentage()}
                    text={`${calcPercentage()}%`}
                    styles={buildStyles({
                      textColor: "green",
                      pathColor: "#1d4442",
                      trailColor: "#e9ece0",
                    })}
                  />
                </span>
              </div>
            </div>
            <span
              style={{ width: "max-content" }}
              className="d-flex justify-content-between"
            >
              <h6>{data?.currentInvestedAmount}&nbsp;</h6>
              <h4>/ {data?.totalInvestment}</h4>
            </span>
          </div>
        </div>
        <div>
          <h4 className="mt-3" id="details">
            About {data?.companyName}
          </h4>
          <p>{data?.aboutSeller}</p>
          <h4 className="mt-4">Assets Details</h4>
          <p>{data?.assetsDetail}</p>
          <h4 className="mt-4">Passive</h4>
          <p>{data?.passive}</p>
        </div>
      </div>
      <hr />
      <div className={styles.ccardPoints}>
        <div className={styles.ccdiv}>
          <p>Minimum Investment</p>
          <h3 className={styles.cch3}>₹ {data?.minimumInvestment}</h3>
        </div>

        <div className={styles.ccdiv}>
          <p>Face Value</p>
          <h3 className={styles.cch3}>₹ {data?.faceValue}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Pre Tax Returns</p>
          <h3 className={styles.cch3}>{data?.preTaxReturns}%</h3>
        </div>

        <div className={styles.ccdiv}>
          <p>Coupon Rate</p>
          <h3 className={styles.cch3}>{data?.couponRate}%</h3>
        </div>
      </div>
      <div className={styles.ccardPoints}>
        <div className={styles.ccdiv}>
          <p>Date of Issue</p>
          <h3 className={styles.cch3}>{data?.dateOfIssue?.slice(0, 10)} </h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Maturity Date</p>
          <h3 className={styles.cch3}>{data?.maturityDate?.slice(0, 10)}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>ISIN</p>
          <h3 className={styles.cch3}>{data?.ISIN}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Transaction Type</p>
          <h3 className={styles.cch3}>{data?.transactionType}</h3>
        </div>
      </div>
      <div className={styles.ccardPoints}>
        <div className={styles.ccdiv}>
          <p>Company Preference</p>
          <h3 className={styles.cch3}>{data?.companyPreference}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Opportunity</p>
          <h3 className={styles.cch3}>{data?.opportunity}</h3>
        </div>

        <div className={styles.ccdiv}>
          <p>Defence</p>
          <h3 className={styles.cch3}>{data?.defence}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Time To Maturity</p>
          <h3 className={styles.cch3}>{data?.timeToMaturity} months</h3>
        </div>
      </div>
      <div className={styles.ccardPoints}>
        <div className={styles.ccdiv}>
          <p>TotalInvestment</p>
          <h3 className={styles.cch3}>{data?.totalInvestment}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Time-Line</p>
          <h3 className={styles.cch3}>{data?.timeLine}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Maximum Return</p>
          <h3 className={styles.cch3}>{data?.maximumReturn}</h3>
        </div>
        <div className={styles.ccdiv}>
          <p>Market</p>
          <h3 className={styles.cch3}>{data?.market}</h3>
        </div>
      </div>

      <hr />

      <h3 className="mt-5">Disclosures & Risks</h3>
      <div className="ms-3">
        <h5>About the Sellers</h5>
        <p>{data?.aboutSeller}</p>
        <h5 id="Risk">Exit Options</h5>
        <p>{data?.exitOptions}</p>
        <h5 id="FAQs">Risk Factors</h5>
        <p>{data?.riskFactors}</p>
        <h5>FAQs</h5>

        <p>{data?.FAQs}</p>
      </div>

      <ModalView open={open2} handleClose={handleClose2}>
        <form
          onSubmit={rejectListingHandler}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ textAlign: "center" }}>Reason</h4>
          <textarea
            required
            onChange={(e) => setReason(e.target.value)}
            name="reason"
            id="reason"
            style={{ width: "100%", outline: "none", marginBottom: "30px" }}
            rows="10"
          ></textarea>
          <div>
            <Button
              className={styles.btnPrimary}
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#1D4442",
                boxShadow: "none",
                marginRight: "10px",
              }}
            >
              {loading2 ? <CircularLoader color="white" /> : "Reject"}
            </Button>
            <Button
              className={styles.btnSecondary}
              onClick={handleClose2}
              variant="outlined"
              style={{
                border: "1px solid #1D4442",
                boxShadow: "none",
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </ModalView>

      <ModalView open={open3} handleClose={handleClose3}>
        <form
          onSubmit={approveListingHandler}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ textAlign: "center" }}>Commission Percentage</h4>
          <input
            required
            onChange={(e) => setCommission(e.target.value)}
            name="commission"
            id="commission"
            type="number"
            style={{ width: "100%", outline: "none", marginBottom: "30px" }}
            rows="10"
          ></input>
          <div>
            <Button
              className={styles.btnPrimary}
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#1D4442",
                boxShadow: "none",
                marginRight: "10px",
              }}
            >
              {loading2 ? <CircularLoader color="white" /> : "Approve"}
            </Button>
            <Button
              className={styles.btnSecondary}
              onClick={handleClose3}
              variant="outlined"
              style={{
                border: "1px solid #1D4442",
                boxShadow: "none",
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </ModalView>

      <ModalView open={open} handleClose={handleClose}>
        <h3>Add Tags</h3>
        <TagsInput value={tags} onChange={setTags} />
        <Button
          onClick={addTagsHandler}
          style={{ marginTop: "20px" }}
          variant="contained"
        >
          {loading3 ? <CircularLoader color="white" /> : "Add"}
        </Button>
      </ModalView>

      <EditModal list={data} isEdit={isEdit} setIsEdit={setIsEdit} />
    </div>
  );
};

export default ListingDetail;
