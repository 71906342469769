import ListingDetail from 'components/ListingDetail/ListingDetail';
import NavSidebar from 'components/NavSidebar/NavSidebar';
import { getUnApprovedListingDetailReq } from 'context/apis/api';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Skeleton from 'ui/ListingSkelton/ListingSkelton';
import styles from './UnApprovedListingDetails.module.css';

const UnApprovedListingDetails = () => {
  const {listingId} = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUnApprovedListingById = async() => {
    try {
      setLoading(true);
      const res = await getUnApprovedListingDetailReq(listingId);
      if(res?.data?.success) {
        setListing(res?.data?.listing);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getUnApprovedListingById();
  }, [listingId])
  return (
    <NavSidebar>
      <div className={styles.wrapper}>
        {
          loading ? (
            <Skeleton />
          ) : (
            <ListingDetail data={listing} isApproved={false} />
          )
        }
      </div>
    </NavSidebar>
  )
}

export default UnApprovedListingDetails;