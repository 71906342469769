import React, { useState } from "react";
import "./table.css";
import NTableRow from "./NTableRow";
import { Modal } from "react-bootstrap";
import JTableRow from "./JTableRow";

const JTable = ({ UserData }) => {
  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Position</th>
            <th>Resume</th>
          </tr>
        </thead>
        <tbody>
          {UserData &&
            UserData.map((item, index) => {
              return (
                <JTableRow
                  id={item._id}
                  key={index}
                  index={index}
                  name={item.name}
                  email={item.email}
                  phone={item.number}
                  position={item?.position}
                  resume={item?.resume}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default JTable;
